import { useEffect, useRef, useState } from 'react';
import { useMeeting } from '../meeting/meetingProviderContextDef';

/**
 *
 * @param {string} topic
 * @param {{
 *  onMessageReceived?: Function,
 *  onOldMessagesReceived?: Function,
 * }} options
 * @returns {{
 *  publish: (message: string,{persist: boolean, sendOnly: Array<String>}, payload: object) => void
 *  messages: Array<{
 *    id: string,
 *    message: string,
 *    senderId: string,
 *    senderName: string,
 *    timestamp: string,
 *    topic: string,
 *    payload: object,
 *  }>
 * }}
 */
const usePubSub = (
  topic,
  { onMessageReceived = () => {}, onOldMessagesReceived = () => {} } = {}
) => {
  const { meeting, isMeetingJoined } = useMeeting();
  const [messages, setMessages] = useState([]);
  const isSubscribed = useRef(false);

  /**
   *
   * @param {string} message
   * @param {{persist: boolean}} options
   * @param {object} payload
   * @param {Array<String>} sendOnly
   */
  const publish = (message, options, payload) => {
    if (meeting) {
      meeting.pubSub.publish(topic, message, options, payload);
    }
  };

  const _handlePubSub = data => {
    setMessages(s => [...s, data]);
    onMessageReceived(data);
  };

  const subscribeToPubsub = async meeting => {
    const data = await meeting?.pubSub?.subscribe(topic, _handlePubSub);
    if (data) {
      onOldMessagesReceived(data.messages);
      setMessages(data.messages);
    }
  };

  useEffect(() => {
    if (meeting && !isSubscribed.current && isMeetingJoined) {
      isSubscribed.current = true;
      subscribeToPubsub(meeting);

      return () => {
        meeting?.pubSub?.unsubscribe(topic, _handlePubSub);
      };
    }
  }, [meeting, isMeetingJoined]);

  return { publish, messages };
};

export default usePubSub;
