import { VideoSDK } from '@videosdk.live/js-sdk';
import { useEffect } from 'react';

/**
 *
 * @param {{
 *  onDeviceChanged?: Function | undefined,
 * }}
 *
 * @returns {{
 *   getDevices: () => Promise<Array<DeviceInfo>>;
 *   getCameras: () => Promise<Array<CameraDeviceInfo>>;
 *   getMicrophones: () => Promise<Array<MicrophoneDeviceInfo>>;
 *   getPlaybackDevices: () => Promise<Array<PlaybackDeviceInfo>>;
 *   checkPermissions: (permissions? : Permission) => Promise<Map<string, boolean>>;
 *   requestPermission: (permissions? : Permission) => Promise<Map<string, boolean>>;
 * }}
 *
 */

const useMediaDevice = ({ onDeviceChanged = () => {} } = {}) => {
  /**
   *
   * @returns {Promise<Array<DeviceInfo>>}
   */
  const getDevices = async () => {
    return await VideoSDK.getDevices();
  };

  /**
   *
   * @returns {Promise<Array<CameraDeviceInfo>>}
   */
  const getCameras = async () => {
    return await VideoSDK.getCameras();
  };

  /**
   *
   * @returns {Promise<Array<MicrophoneDeviceInfo>>}
   */
  const getMicrophones = async () => {
    return await VideoSDK.getMicrophones();
  };

  /**
   *
   * @returns {Promise<Array<PlaybackDeviceInfo>>}
   */
  const getPlaybackDevices = async () => {
    return await VideoSDK.getPlaybackDevices();
  };

  /**
   *
   * @param {permissions?: Permission}
   * @returns {Promise<Map<string, boolean>>}
   */
  const checkPermissions = async (permissions = undefined) => {
    return await VideoSDK.checkPermissions(permissions);
  };

  /**
   *
   * @param {permission? : Permission}
   * @returns {Promise<Map<string, boolean>>}
   */
  const requestPermission = async (permissions = undefined) => {
    return await VideoSDK.requestPermission(permissions);
  };

  useEffect(() => {
    VideoSDK.on('device-changed', onDeviceChanged);

    return () => {
      VideoSDK.off('device-changed', onDeviceChanged);
    };
  }, []);

  return {
    getDevices,
    getCameras,
    getMicrophones,
    getPlaybackDevices,
    requestPermission,
    checkPermissions
  };
};

export default useMediaDevice;
